import { configureStore } from '@reduxjs/toolkit'
import canvasReducer from '@/modules/canvas/canvasSlice'
import { contentSlice } from '@/modules/content/contentSlice'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { preferencesSlice } from '@/modules/preferences/preferences-slice'

const persistConfig = {
	key: 'canvas',
	storage,
}
const persistedContentReducer = persistReducer(
	persistConfig,
	contentSlice.reducer
)
const persistedPreferencesReducer = persistReducer(
	persistConfig,
	preferencesSlice.reducer
)

export const store = configureStore({
	reducer: {
		canvas: canvasReducer,
		content: persistedContentReducer,
		preferences: persistedPreferencesReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
			},
		}),
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
