'use client'

import { persistor, store } from '@/lib/redux/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';

export default function RootLayout({
	children,
}: {
	children: React.ReactNode
}) {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<div className='h-screen w-screen'>{children}</div>
			</PersistGate>
		</Provider>
	)
}
